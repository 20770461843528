<template>
  <div class='m-1 mb-5 mt-3'>
    <b-card
      class='d-flex mb-5'
      header='Minha conta'
      header-text-variant='white'
      header-tag='header'
      header-bg-variant='info'
      deck
    >
      <ul>
        <li>
          <p>
            <strong>E-mail: </strong> <span>{{user.username}}</span>
          </p>
        </li>
      </ul>
      <router-link
        v-for='(item, i) in linksMenuMyAccount'
        v-if='linksMenuMyAccountFiltered(item.link)'
        :key='i'
        :id='`${i}_menu_sub`'
        active-class='link-clicked'
        class='listMenuItem '
        style='margin-left: -20px'
        :to='{ name: `${item.link}` }'
      >
        <div style='width: 15px'>
          <span>
            <i :class='`icon-link-sub-menu ${item.icon}`'></i>
          </span>
        </div>

        <span class='label' v-html='item.label'></span>
      </router-link>
    </b-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MyAccount',
  data() {
    return {
      linksMenuMyAccount: [
        {
          link: 'Profile',
          icon: 'fas fa-user-circle',
          label: 'Perfil',
          role: '',
          active: true
        },
        {
          link: 'ChangePlan',
          icon: 'fas fa-user',
          label: 'Meu plano',
          role: '',
          active: true
        },
        {
          link: 'RedefinePwd',
          icon: 'fas fa-lock',
          label: 'Redefinir senha',
          role: '',
          active: true
        },
        {
          link: 'Colaboradores',
          icon: 'fas fa-user-plus',
          label: 'Colaboradores',
          role: '',
          active: true
        },
        {
          link: 'Indicacao',
          icon: 'fas fa-link',
          label: 'Indicação',
          role: '',
          active: true
        },
        {
          link: 'Policy',
          icon: 'fas fa-file',
          label: 'Política de privacidade e <br />termos de uso</span>',
          role: '',
          active: true
        },
        {
          link: 'Preferencias',
          icon: 'fas fa-user',
          label: 'Preferências do usuário',
          role: '',
          active: true
        }
      ]
    }
  },
  created() {
    const breadcrumb = {
      tab: '',
      page: 'Minha conta',
      subPage: {
        name: 'Minha conta',
        active: true
      },
      tabs: []
    }
    this.setBreadcrumb(breadcrumb)
  },
  async mounted() {
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    }
  },
  methods: {
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb'
    }),
    linksMenuMyAccountFiltered(link) {
      if (link !== 'Colaboradores') {
        return true
      }
      return this.user.plan && (this.user.plan.status === 'paid' || this.user.endDateExtraAccessTime > 0) && (this.user.plan.plan === 'AN1HO' || this.user.plan.plan === 'PROMO')
    }
  }
}
</script>

<style lang='scss'>
.link-clicked {
  background-color: rgba(4, 168, 243, 0.4) !important;
  border-radius: 2px;
  color: #fff !important;
  width: 100%;
}

.link-clicked span.icon {
  color: #022245 !important;
}

.label {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 17px;
  color: #022245 !important;
}

.listMenuItem {
  padding: 5px 20px !important;
  display: flex;
  align-items: center;
  gap: 13px;
  text-decoration: none;
}

.icon-link-sub-menu{
  color: #022245 !important;
  font-size: 20px;
}
ul {
  list-style: none;
}
</style>
